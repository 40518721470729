<template>
	<div>
		<PageTitle title="就诊记录" fixed backTo="/visit"></PageTitle>
		<List :width="100">
			<div style="position: relative;width: 85%;margin: 15px auto auto auto;">
				<ListItem :leftWidth="0" :rightWidth="100" :style="height">
					<template #top>
						<p>处方详情</p>
					</template>
					<template #right>
						<div>
							<p>就诊卡号：{{ item.jzkh && item.jzkh.substring(0, 4) + '****' + item.jzkh.substring(item.jzkh.length - 4, item.jzkh.length) }}</p>
						</div>
						<div style="float: left">
							<p>姓名：{{ item.xm }}</p>
							<p>年龄：{{ item.nl }}</p>
						</div>
						<div style="float: left;margin-left: 50px">
							<p>性别：{{ item.xb }}</p>
							<p>民族：{{ item.mz }}</p>
						</div>
						<div style="clear: both" class="div-mt">
							<p>执业点：{{ item.ksmc }}</p>
							<p>开方医生：{{ item.ygxm }}</p>
							<p>收货人：{{item.sjrxm}}</p>
							<div style="width: 95%; display: flex; justify-content: space-between;">
								<div style="width: 75%;">
									<p>收货地址：{{item.address}}</p>
									<p>{{item.specificAddress}}</p>
								</div>
								<div>
									<van-button type="primary" size="small" @click="showAddressModal" :disabled="ddzt == 1">修改地址</van-button>
								</div>
							</div>
							<div v-if="item.pd == '1' && item.diagnosisList[0].jbmc != null">
								<p>诊断：</p>
								<div v-for="(v1, index) in item.diagnosisList" :key="index">
									<div>
										<p>{{ index + 1 }}.{{ v1.jbmc }}</p>
									</div>
								</div>
							</div>
							<div v-else><p>诊断：未开具</p></div>
						</div>

						<div v-if="item.pd == '1' && item.ypxx1List[0].ypmc != null">
							<p style="padding-top: 10px">药品名称：</p>
							<div v-for="(v, index) in item.ypxx1List" :key="index">
								<p>{{ index + 1 }}.{{ v.ypmc }} ({{ v.yfgg }}) 共{{ v.ypsl }}{{ v.yfdw }}</p>
								<p style="color: #76B49C;margin-left: 10px;">用法：{{ v.cfbz }} {{ v.xmmc }}</p>
							</div>
						</div>
						<div class="div-mt width-half">
							<div><p>挂号费：</p></div>
							<div v-if="item.ghf != '暂无'">
								<p>￥{{ item.ghf }}元</p>
							</div>
							<div v-else>
								<p>{{ item.ghf }}</p>
							</div>
							<div><p>药品费用：</p></div>
							<div v-if="item.totalFee != '暂无'">
								<p>￥{{ item.totalFee }}元</p>
							</div>
							<div v-else>
								<p>{{ item.totalFee }}</p>
							</div>
							<div><p>药师审核费：</p></div>
							<div>
								<p>{{ item.ysshf }}</p>
							</div>
							<div><p>快递费（顺丰快递）：</p></div>
							<div>
								<p>{{ item.kdf }}</p>
							</div>
						</div>
						<div style="float: right">
							<p style="font-size: 14px" v-if="item.hjfy != '暂无'">合计费用 ￥{{ item.hjfy }}元</p>
							<p style="font-size: 14px" v-else>合计费用 {{ item.hjfy }}</p>
						</div>
						<div style="margin-top: 30px">
							<p>开具时间：{{ GetSJS(item.yysj) }}</p>
						</div>
					</template>
				</ListItem>
				<ListItem :leftWidth="50" :rightWidth="50">
					<template v-slot:bottom>
						<p style="text-align: center;">我的咨询</p>
						<div class="borderColor" style="margin-top: 10px" v-if="ZX[0]" @click="TZ(ZX[0].targetId, ZX[0].applyInfoId, ZX[0].avatarUrl)">
							<van-icon :badge="ZX[0].unReadNum" style="float: right" v-if="ZX[0].unReadNum != '0'" />
							<div class="leftArea">
								<img :src="ZX[0].avatarUrl" style="height:44px;width:44px;border-radius: 50%;float: left" />
								<p class="fontmd" style="float: left;margin-left: 5px">{{ ZX[0].doctorName }}</p>
								<p class="fontsm" style="float: left;margin-top: 3px;margin-left: 2px">{{ ZX[0].doctorLevel }}</p>
								<p class="fontsm" style="float: right;margin-left: 2px;margin-top: 3px">{{ GetSJSS(ZX[0].sendDate) }}</p>
								<p class="fontmd" style="margin-left: 50px; margin-top: 30px;color: #76B49C; ">{{ ZX[0].msg }}</p>
							</div>
						</div>
					</template>
					<!-- 输入任意文本 -->
				</ListItem>
			</div>
		</List>
		<van-button type="primary" block @click="GetZLYD()" style="width: 85%;bottom:0;position: fixed;margin-left: 7.5%;margin-bottom: 5%" v-if="item.sfzlyd == '1'">
			再来一单
		</van-button>
		<van-button type="primary" block @click="showModal()" style="width: 85%;bottom:0;position: fixed;margin-left: 7.5%;margin-bottom: 5%" v-if="pd == 0">取消申请</van-button>

		<!-- 修改地址对话框 -->
		<van-dialog v-model="showAddress" class="addressModal">
			<!-- <van-address-edit
				:area-list="areaList"
				show-search-result
				delete-button-text='取消'
				@save="onSave"
			>
			<van-button style="width:90%; margin-left:4px; position: absolute; bottom: 10px;" @click="onDelete">取消</van-button>
			</van-address-edit> -->
			<div style="margin-top: 0px;">
				<Address ref="address" @onSave="onSave" :type="1">
					<template v-slot:add>
						<ListItem
							@click="
								() => {
									addVisible = true;
								}
							"
							style="margin-top: 15px;"
						>
							<template #top>
								<van-icon name="add-o" style="font-size: 24px;position: relative;top: 6px;" />
								<span style="margin-left: 8px;">添加新地址</span>
							</template>
						</ListItem>
					</template>
					<template v-slot:cancelBtn>
						<van-button block @click="showAddress = false" style="margin-top: 10px;">取消</van-button>
					</template>
				</Address>
			</div>
		</van-dialog>

		<!-- 新增地址对话框 -->
		<van-dialog v-model="addVisible" class="addressModal" style="width: 90%;">
			<div>
				<AddAddress ref="addAddress" :type="1" @getMsg="getMsg">
					<template v-slot:cancelBtn>
						<van-button block @click="() => {
							addVisible = false;
							showAddress = true;
						}" style="margin-top: 10px;">取消</van-button>
					</template>
				</AddAddress>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import List from '@/components/home-page/List.vue';
import ListItem from '@/components/home-page/ListItem.vue';
import { GetJLXQ, GetDDZX, CancelApplication, ChangeAddress } from '@/api/check.js';
import areaList from '@/api/area.js';
import Address from '@/views/Address.vue';
import AddAddress from '@/views/AddAddress';
export default {
	components: {
		PageTitle,
		List,
		ListItem,
		Address,
		AddAddress
	},
	data() {
		return {
			items: [],
			item: '',
			//                    {"sfzlyd":"0","sjrxm":"","sjrhm":null,"ddbm":1307356418,"jzkh":"","xm":"","xb":"","nl":0,"mz":"","ksmc":"","ygxm":"","jbmc":"未开具","rp":"暂无","sig":"暂无","ghf":0,"ghF1":"暂无","totalFee":0,"totalFee1":"暂无","ysshf":"暂无","kdf":"暂无","hjfy":0,"hjfY1":"暂无","yysj":null,"kjsj":"暂无","sflyzq":"来院自取","sjrxxdz":null,"sjrsf":null,"sjrqs":null,"sjrxj":null,"sjrzj":null,"diagnosisList":[],"ypxx1List":[]}
			height: {
				height: '',
				overflow: 'auto'
			},
			height1: {
				height: '',
				overflow: 'auto'
			},
			margin: {
				left: ''
			},
			jzxh: this.$route.params.jzxh,
			pd: this.$route.params.pd,
			ddzt: this.$route.params.ddzt,
			lock: false,
			ZX: '',
			areaList,
			showAddress: false,
			addVisible: false
		};
	},
	methods: {
		hh() {
			GetJLXQ(this.jzxh, this.pd).then(
				result => {
					if (this.pd == '1') {
						this.item = {
							jzkh: result.jzkh,
							xm: result.xm,
							nl: result.nl,
							xb: result.xb,
							mz: result.mz,
							ksmc: result.ksmc,
							ygxm: result.ygxm,
							jbmc: result.jbmc,
							ypxx1List: result.ypxx1List,
							ghf: result.ghf,
							totalFee: result.totalFee,
							ysshf: result.ysshf,
							kdf: result.kdf,
							hjfy: result.hjfy,
							yysj: result.yysj,
							pd: this.pd,
							sfzlyd: result.sfzlyd,
							ddmb: result.ddbm,
							diagnosisList: result.diagnosisList,
							address: result.sjrxm == '来院自取' ? '' : result.sjrsf + result.sjrqs + result.sjrxj + result.sjrzj,
							sjrxm: result.sjrxm,
							specificAddress: result.sjrxxdz,
						};
					} else {
						this.item = {
							jzkh: result.jzkh,
							xm: result.xm,
							nl: result.nl,
							xb: result.xb,
							mz: result.mz,
							ksmc: result.ksmc,
							ygxm: result.ygxm,
							jbmc: result.jbmc,
							ypxx1List: result.ypxx1List,
							ghf: result.ghF1,
							totalFee: result.totalFee1,
							ysshf: result.ysshf,
							kdf: result.kdf,
							hjfy: result.hjfY1,
							yysj: '暂无',
							pd: this.pd,
							sfzlyd: result.sfzlyd,
							ddmb: result.ddbm,
							diagnosisList: result.diagnosisList,
							address: result.sjrxm == '来院自取' ? '' : result.sjrsf + result.sjrqs + result.sjrxj + result.sjrzj,
							specificAddress: result.sjrxxdz,
							sjrxm: result.sjrxm,
						};
					}
					GetDDZX(this.item.ddmb).then(
						results => {
							this.ZX = results;
							this.items = this.ZX;
							this.doConnect(this);
						},
						() => {}
					);
				},
				() => {}
			);
		},
		TZ(e, f, h) {
			this.$router.push({ path: '/consult/' + e + '/' + f, query: { image: h } });
			localStorage.setItem('address1', this.jzxh);
			localStorage.setItem('address2', this.pd);
			localStorage.setItem('payOpenIds', '');
		},
		doConnect(app) {
			let that = this;
			if (this.$store.SaveSocket && this.$store.SaveSocket.readyState == WebSocket.OPEN) {
				this.socket = this.$store.SaveSocket;
			} else {
				let uri = 'wss://cloudhospitalapi.knjs.net/ws?senderId=patient-' + app.ZX[0].senderId + '&targetId=-1';
				this.socket = new WebSocket(uri);
				this.$store.SaveSocket = this.socket; //webscoket实例 存进全局
			}
			this.socket.onopen = function() {
				that.heartbeatInstance = setInterval(
					() => {
						if (that.socket && that.socket.readyState == WebSocket.OPEN) {
							that.heartbeat();
						} else {
							if (that.heartbeatInstance) {
								clearInterval(that.heartbeatInstance);
							}
						}
					},
					50000,
					that
				);
			};

			this.socket.onclose = function() {
				if (!that.isDestroyed) {
					setTimeout(
						() => {
							that.doConnect(that);
						},
						5000,
						that
					);
				}
			};

			this.socket.onmessage = function(e) {
				let msg = JSON.parse(e.data);
				if (msg.SenderId == '-1' && msg.MsgType != '6') {
					app.items.unReadNum = msg.totalUnReadNum;
					app.items.detail[0].msg.title = JSON.parse(msg.Msg).title;
					app.items.detail[0].sendDate = msg.SendDate;
					app.items.detail[0].msg.content = JSON.parse(msg.Msg).content;
				} else {
					for (let m = 0; m < app.ZX.length; m++) {
						if (msg.SenderId == app.ZX[m].targetId) {
							app.ZX[m].unReadNum = msg.unReadNum;
							app.ZX[m].msg = msg.Msg;
							app.ZX[m].sendDate = msg.SendDate;
							app.items[m].unReadNum = msg.unReadNum;
							app.items[m].msg = msg.Msg;
							app.items[m].sendDate = msg.SendDate;
						}
					}
				}
			};
		},
		heartbeat() {
			this.socket.send(
				JSON.stringify({
					msg: '',
					msgtype: '6',
					senderid: 'patient-' + this.ZX[0].senderId,
					targetId: -1,
					id: ''
				})
			);
		},
		GetZLYD() {
			//通过push进行跳转
			this.$router.push('/encore/' + this.jzxh);
		},
		GD() {
			this.height.height = document.documentElement.clientHeight - 300 + 'px';
			this.height1.height = 120 + 'px';
			this.margin.left = (document.documentElement.clientWidth - 150) / 2 + 'px';
		},
		GetSJ(e) {
			if (e != null) {
				if (this.$util.moment(e).format('YYYY-MM-DD') == this.$util.moment().format('YYYY-MM-DD')) {
					return this.$util.moment(e).format('HH:mm');
				} else {
					//                        return "暂无";
				}
			}
		},
		GetSJSS(e) {
			if (e != null) {
				if (this.$util.moment(e).format('YYYY-MM-DD') == this.$util.moment().format('YYYY-MM-DD')) {
					return this.$util.moment(e).format('HH:mm');
				} else {
					return this.$util.moment(e).format('YYYY-MM-DD');
				}
			}
		},
		GetSJS(e) {
			if (e != '暂无' && e != null) {
				return this.$util.moment(e).format('YYYY-MM-DD HH:mm:ss');
			} else {
				return '暂无';
			}
		},
		showModal() {
			this.$dialog
				.confirm({
					title: '',
					message: '是否确认取消申请'
				})
				.then(() => {
					CancelApplication(this.item.ddmb).then(() => {
						this.$toast('取消申请成功');
						this.$router.push({ name: 'Visit' });
					});
					// console.log(this.$router.push('/visit'))
				})
				.catch(() => {});
		},
		showAddressModal() {
			this.showAddress = true;
		},
		getMsg(data) {
			if (data == '新增成功') {
				// this.showAddressModal();
				this.addVisible = false;
				this.$refs.address.GetUserAddress();
				// location.reload();
			}
			this.$toast(data);
		},

		onSave(content) {
			console.log(content);
			let params = {}
			if (content) {
				params = {
					ddbm: this.item.ddmb,
					sjr_Name: content.addressee,
					sjr_Phone: content.phone,
					sjr_Province: content.province,
					sjr_City: content.city,
					sjr_County: content.area,
					sjr_Street: content.street,
					sjr_SpecificAddress: content.specificAddress
				};
			} else {
				params = {
					ddbm: this.item.ddmb,
					sjr_Name:  '来院自取',
					sjr_Phone: '',
					sjr_Province: '',
					sjr_City: '',
					sjr_County: '',
					sjr_Street: '',
					sjr_SpecificAddress: ''
				}
			}
			ChangeAddress(params)
				.then(() => {
					this.$toast('修改成功');
					location.reload();
				})
				.catch(() => {});
		}
	},
	created: function() {
		// init sendMsg object
		this.hh();
		this.GD();
	}
};
</script>

<style scoped="" lang="less">
@import '../style/var.less';
.div-mt {
	margin-top: 10px;
}
.width-half {
	div {
		width: 50%;
		display: inline-block;
	}
	div:nth-child(2n) p {
		float: right;
		color: #ff2600;
	}
	div:nth-child(2n + 1) p {
		float: left;
	}
}
.infoContainer {
	padding: 10px;

	p {
		margin: 0;
	}
	.top p {
		text-align: center;
		margin: 0 0 10px;
	}
	.right {
		p {
			font-size: @font-size-sm;
		}
	}
}
.borderColor {
	background-color: #f6f6f6;
	border-radius: 8px;
	padding: 10px;
	margin: 0 10px;
	p {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.leftArea {
		width: 100%;
		display: inline-block;
	}
	.rightArea {
		width: 15%;
		display: inline-block;
		vertical-align: top;
		p {
			float: right;
		}
		.van-icon {
			float: right;
			margin-top: 40px;
		}
	}
}
.fontmd {
	font-size: @font-size-md;
}
.fontsm {
	font-size: @font-size-sm;
}
.div-mt {
	margin-top: 10px;
}
.width-half {
	div {
		width: 50%;
		display: inline-block;
	}
	div:nth-child(2n) p {
		float: right;
		color: #ff2600;
	}
	div:nth-child(2n + 1) p {
		float: left;
	}
}
/deep/ .addressModal .van-dialog__footer {
	display: none;
}
</style>
